import swal from "sweetalert";

// Loader
window.addEventListener("load", () => {
  const loader = document.querySelector(".loader");

  loader.classList.add("loader--hidden");

  loader.addEventListener("transitionend", () => {
    document.body.removeChild(loader);
  });
});

// Country code numbers
const input = document.querySelector("#telephone");
const iti = window.intlTelInput(input, {
  initialCountry: "ma",
  strictMode: true,
  // preferredCountries: ["ma", "ae", "us"],
  utilsScript:
    "https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js",
});

// Slick for before VS after Photos
$(document).ready(() =>
  $(".bf-photos-container").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 1300,
    infinite: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4, arrows: false } },
      { breakpoint: 768, settings: { slidesToShow: 3, arrows: false } },
      { breakpoint: 480, settings: { slidesToShow: 2, centerMode: false, arrows: false } },
    ],
  }),
);

// Slick for Testimonial Videos
$(document).ready(() =>
  $(".slider-container").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 770, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
    ],
  }),
);

document.addEventListener("DOMContentLoaded", function () {
  // Get the elements
  const mobaOpenBtn = document.querySelector(".moba-open-btn");
  const mobaCloseBtn = document.querySelector(".moba-close-btn");
  const mobileMenuContainer = document.querySelector(".mobile-menu-container");

  // Function to toggle the mobile menu
  function toggleMobileMenu() {
    mobileMenuContainer.classList.toggle("hide");
  }

  // Function to hide the mobile menu
  function hideMobileMenu() {
    mobileMenuContainer.classList.add("hide");
  }

  // Event listeners to open and close the menu
  if (mobaOpenBtn && mobaCloseBtn && mobileMenuContainer) {
    mobaOpenBtn.addEventListener("click", toggleMobileMenu);
    mobaCloseBtn.addEventListener("click", toggleMobileMenu);
  } else {
    console.error("One or more elements not found.");
  }

  // Event listeners for each link to close the menu when clicked
  const mobileMenuLinks = document.querySelectorAll(
    ".mobile-menu-container nav ul li a"
  );
  mobileMenuLinks.forEach((link) => {
    link.addEventListener("click", hideMobileMenu);
  });

  // Intersection Observer for mobile menu
  const sections = document.querySelectorAll("section");
  const mobileNavLinks = document.querySelectorAll(
    ".mobile-menu-container nav ul li a"
  );

  const mobileObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          mobileNavLinks.forEach((link) => link.classList.remove("active"));

          const activeLink = document.querySelector(
            `.mobile-menu-container nav ul li a[href="#${entry.target.id}"]`
          );
          if (activeLink) {
            activeLink.classList.add("active");
          }
        }
      });
    },
    { threshold: 0.6 }
  );

  sections.forEach((section) => {
    mobileObserver.observe(section);
  });
});

const headerContainer = document.querySelector(".header-container");
const header = document.querySelector(".header");
let lastScrollTop = 0;

// Function to update the header position based on scroll direction
function updateHeaderOnScroll() {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  if (scrollTop === 0) {
    // User is at the top of the page, show the header
    header.style.transform = "translate(-50%, -50%)";
  } else if (scrollTop > lastScrollTop) {
    // Scrolling down - hide the header
    header.style.transform = "translate(-50%, -300%)";
  } else {
    // Scrolling up - show the header
    header.style.transform = "translate(-50%, -50%)";
  }

  lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Prevent negative scrolling issues
}

// Initial setup to ensure the correct header state on page load
window.addEventListener("load", function () {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (scrollTop > 0) {
    // If the page is scrolled down, hide the header initially
    header.style.transform = "translate(-50%, -300%)";
  } else {
    // If the page is at the top, show the header
    header.style.transform = "translate(-50%, -50%)";
  }
  lastScrollTop = scrollTop;
});

// Handle hover events to show the header
headerContainer.addEventListener("mouseenter", () => {
  header.style.transform = "translate(-50%, -50%)"; // Show the header
  header.classList.add("hovered-header");
});

// Handle mouse leave to reset based on scroll position
headerContainer.addEventListener("mouseleave", () => {
  header.classList.remove("hovered-header");
  if (window.pageYOffset > 0) {
    // Only hide if scrolled down
    header.style.transform = "translate(-50%, -300%)";
  }
});

// Update header position on scroll
window.addEventListener(
  "scroll",
  function () {
    if (!header.classList.contains("hovered-header")) {
      updateHeaderOnScroll();
    }
  },
  false
);

// auto-selection of links in the header menu based on the section currently visible as you scroll
document.addEventListener("DOMContentLoaded", function () {
  // Get all section elements
  const sections = document.querySelectorAll("section");
  const navLinks = document.querySelectorAll("nav ul li a");

  // Create an IntersectionObserver
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Remove active class from all nav links
          navLinks.forEach((link) => link.classList.remove("active"));

          // Add active class to the nav link corresponding to the section in view
          const activeLink = document.querySelector(
            `nav ul li a[href="#${entry.target.id}"]`
          );
          if (activeLink) {
            activeLink.classList.add("active");
          }
        }
      });
    },
    { threshold: 0.6 } // Adjust this threshold as needed
  );

  // Observe each section
  sections.forEach((section) => {
    observer.observe(section);
  });
});

///////////////////////////////////////////////////////////
//------------- POPUPS PHOTO CONTAINER
// Select the popup container, close button, and the image inside the popup
const popupPhotoContainer = document.querySelector(".popup-photo-container");
const closePhotoBtn = document.getElementById("close-pop-photo");
const popupImage = popupPhotoContainer.querySelector("img");

// Select all div elements that contain images in the before-after section
const photos = document.querySelectorAll(".bf-photos-container div");

// Event listener to close the popup when the close button is clicked
closePhotoBtn.addEventListener("click", () => {
  popupPhotoContainer.classList.add("hide");
});

// Add click event listener to each photo container
photos.forEach((photo) => {
  photo.addEventListener("click", () => {
    const imgElement = photo.querySelector("img");
    if (imgElement) {
      const imgSrc = imgElement.src;
      popupImage.src = imgSrc;
      popupPhotoContainer.classList.remove("hide");
    } else {
      console.error(
        "Image element not found within the clicked photo container."
      );
    }
  });
});

popupPhotoContainer.addEventListener("click", () => {
  popupPhotoContainer.classList.add("hide");
});

////////////////////////////////////////////////////////////////////
// reposnive Packs cards for mobile
const scrollContainer = document.querySelector(".scroll-container");
const packs = document.querySelectorAll(".packs-container .pack");
const swipToRight = document.querySelector(".swip-to-right");
const lastPack = document.querySelector(".body-buidling-pack");

// Function to check if the last pack is fully visible
function checkLastPackVisibility() {
  const lastPackRect = lastPack.getBoundingClientRect();
  const containerRect = scrollContainer.getBoundingClientRect();

  // Log values for better debugging
  // console.log("Last Pack Left: ", lastPackRect.left);
  // console.log("Last Pack Right: ", lastPackRect.right);
  // console.log("Container Left: ", containerRect.left);
  // console.log("Container Right: ", containerRect.right);

  // Check if the last pack is fully within the visible area of the scroll-container
  return (
    lastPackRect.left >= containerRect.left && 
    lastPackRect.right <= containerRect.right
  );
}

// Scroll event listener for scaling packs and checking last pack visibility
// Check if the viewport width is less than or equal to 728px
if (window.matchMedia("(max-width: 728px)").matches) {
  scrollContainer.addEventListener("scroll", handleScroll);
}

// Handle the scroll event
function handleScroll() {
  packs.forEach((pack) => {
    const { left, right } = pack.getBoundingClientRect();
    const { left: containerLeft, right: containerRight } = scrollContainer.getBoundingClientRect();

    // Scale the pack based on its visibility within the scroll container
    pack.style.transform = left >= containerLeft && right <= containerRight ? "scale(1.1)" : "scale(1)";
  });

  // Show/hide "Swipe to right" text based on last pack visibility
  toggleSwipeTextVisibility();
}

// Toggle visibility of the "Swipe to right" text based on last pack
function toggleSwipeTextVisibility() {
  const isLastPackVisible = checkLastPackVisibility();

  if (isLastPackVisible) {
    swipToRight.classList.add("hide-swip-text");
  } else {
    swipToRight.classList.remove("hide-swip-text");
  }
}

// Return to the first card on page load
document.addEventListener("DOMContentLoaded", () => {
  const firstCard = document.getElementById("first-card");

  if (firstCard) {
    scrollContainer.scrollLeft = firstCard.offsetLeft;
  }
});



//////////////////////////////////////////
const popFormContainer = document.querySelector(".pop-form-container");

// ------------- Get buttons of packs
const btnBodyBuildersPack = document.getElementById("btn-bodybuilders-pack");
const btnPersonalTrainingPack = document.getElementById(
  "btn-personal-training-pack"
);
const btnVipPack = document.getElementById("btn-vip-pack");
const btnNormalPack = document.getElementById("btn-normal-pack");
const btnBasicPack = document.getElementById("btn-basic-pack");

// Light testto get body building price

// Get The pricing packs
const bodybuildersPricing = document.querySelector(".bodybuilders-pricing");
const personalPricing = document.querySelector(".personal-pricing");
const vipPricing = document.querySelector(".vip-pricing");
const normalPricing = document.querySelector(".normal-pricing");
// const vipPricing = document.querySelector(".vip-pricing");

const totalPriceInput = document.getElementById("total-price");

const monthRadios = document.querySelectorAll(
  ".radio-btns-container input[name='month']"
);

const discountContainer = document.querySelector(".after-discount-container");
const savedPriceInput = document.querySelector(".saved-price");

let selectedPackPrice = 0; // Store the selected pack price
let selectedPlanName = ""; // Store the selected plan name

// Function to get the selected month multiplier and check for discount
function getMonthMultiplier() {
  let multiplier = 1;
  monthRadios.forEach((radio) => {
    if (radio.checked) {
      const monthText = radio.value;
      if (monthText.includes("شهر واحد")) {
        multiplier = 1;
        discountContainer.classList.add("hide"); // Hide discount container
        savedPriceInput.value = ""; // Clear saved price input
      } else if (monthText.includes("3 أشهر")) {
        multiplier = 3;
        applyDiscount(multiplier);
      } else if (monthText.includes("6 أشهر")) {
        multiplier = 6;
        applyDiscount(multiplier);
      } else if (monthText.includes("12 أشهر")) {
        multiplier = 12;
        applyDiscount(multiplier);
      }
    }
  });
  return multiplier;
}

// Function to apply the discount
function applyDiscount(multiplier) {
  discountContainer.classList.remove("hide"); // Show discount container
  const totalPrice = selectedPackPrice * multiplier;
  const discount = Math.trunc(totalPrice * 0.2); // Calculate 20% discount and truncate
  savedPriceInput.value = discount; // Set saved price with discount
  totalPriceInput.value = totalPrice - discount; // Update total price after discount
}

// Function to update total price
function updateTotalPrice() {
  const monthMultiplier = getMonthMultiplier();
  if (discountContainer.classList.contains("hide")) {
    const totalPrice = selectedPackPrice * monthMultiplier;
    totalPriceInput.value = totalPrice; // Set total price without discount
  }
}

// Event listeners for pack selection
btnBodyBuildersPack.addEventListener("click", function () {
  selectedPackPrice = parseInt(
    document.querySelector(".bodybuilders-pricing").textContent.trim()
  );
  selectedPlanName = document
    .querySelector(".bodybuilders-pack-name")
    .textContent.trim();
  updateTotalPrice();
  popFormContainer.classList.remove("hide");
  document.body.style.overflow = "hidden";
  resetDisabledMonths(); // Enable all months by default
  resetMonthsTitle(); // Reset the months title to its default text
});

btnPersonalTrainingPack.addEventListener("click", function () {
  selectedPackPrice = parseInt(
    document.querySelector(".personal-pricing").textContent.trim()
  );
  selectedPlanName = document
    .querySelector(".personal-pack-name")
    .textContent.trim();
  updateTotalPrice();
  popFormContainer.classList.remove("hide");
  document.body.style.overflow = "hidden";
  resetDisabledMonths(); // Enable all months by default
  resetMonthsTitle(); // Reset the months title to its default text

  
  // Remove competitions option from all packs exept Bodybuilding pack
  document.querySelector(".competitions-opt-container").remove();
  swipToRight.classList.add("hide-swip-text");
});

btnVipPack.addEventListener("click", function () {
  selectedPackPrice = parseInt(
    document.querySelector(".vip-pricing").textContent.trim()
  );
  selectedPlanName = document
    .querySelector(".vip-pack-name")
    .textContent.trim();
  updateTotalPrice();
  popFormContainer.classList.remove("hide");
  document.body.style.overflow = "hidden";
  resetDisabledMonths(); // Enable all months by default
  resetMonthsTitle(); // Reset the months title to its default text

  
  // Remove competitions option from all packs exept Bodybuilding pack
  document.querySelector(".competitions-opt-container").remove();
  swipToRight.classList.add("hide-swip-text");

});

btnNormalPack.addEventListener("click", function () {
  selectedPackPrice = parseInt(
    document.querySelector(".normal-pricing").textContent.trim()
  );
  selectedPlanName = document
    .querySelector(".normal-pack-name")
    .textContent.trim();
  updateTotalPrice();
  popFormContainer.classList.remove("hide");
  document.body.style.overflow = "hidden";
  resetDisabledMonths(); // Enable all months by default
  resetMonthsTitle(); // Reset the months title to its default text

  
  // Remove competitions option from all packs exept Bodybuilding pack
  document.querySelector(".competitions-opt-container").remove();
  swipToRight.classList.add("hide-swip-text");

});

btnBasicPack.addEventListener("click", function () {
  selectedPackPrice = 75; // Hardcoded price for the basic pack
  selectedPlanName = document
    .querySelector(".basic-pack-name")
    .textContent.trim();
  updateTotalPrice();
  popFormContainer.classList.remove("hide");
  document.body.style.overflow = "hidden";
  document.getElementById("month3").disabled = true;
  document.getElementById("month6").disabled = true;
  document.getElementById("month12").disabled = true;
  document.querySelector('label[for="month1"]').textContent = "مرة واحدة";
  document.querySelector('label[for="month3"]').style.color = "#ccc";
  document.querySelector('label[for="month6"]').style.color = "#ccc";
  document.querySelector('label[for="month12"]').style.color = "#ccc";
  document.querySelector(".months-title").textContent = `باقة البرامج فقط`; // Update the months title
  // step2.remove();

  // Remove competitions option from all packs exept Bodybuilding pack
  document.querySelector(".competitions-opt-container").remove();
  swipToRight.classList.add("hide-swip-text");

});
// Function to reset the disabled state of the months
function resetDisabledMonths() {
  document.getElementById("month3").disabled = false;
  document.getElementById("month6").disabled = false;
  document.getElementById("month12").disabled = false;
}

// Function to reset the months title to its default text
function resetMonthsTitle() {
  document.querySelector(".months-title").textContent =
    "فترة الاشتراك التي ترغب بها"; // Replace with the default text
}

// Function to reset the disabled state of the months
function resetDisabledMonths() {
  document.getElementById("month3").disabled = false;
  document.getElementById("month6").disabled = false;
  document.getElementById("month12").disabled = false;
}

// Update the total price when the user changes the selected month
monthRadios.forEach((radio) => {
  radio.addEventListener("change", function () {
    updateTotalPrice(); // Recalculate the total price when the month is changed
  });
});

// Function to handle pack button clicks
function handlePackButtonClick(pricingElement) {
  popFormContainer.classList.remove("hide");
  totalPriceInput.value = Number(pricingElement.textContent);
}

// Bodybuilding Button
btnBodyBuildersPack.addEventListener("click", () => handlePackButtonClick(bodybuildersPricing));

// Personal Training Button
btnPersonalTrainingPack.addEventListener("click", () => handlePackButtonClick(personalPricing));

// VIP Button
btnVipPack.addEventListener("click", () => handlePackButtonClick(vipPricing));

// Normal Button
btnNormalPack.addEventListener("click", () => handlePackButtonClick(normalPricing));


const checkbox = document.getElementById("agree-checkbox");
const button = document.getElementById("button");

// Add Functionality to Welcoming step
checkbox.addEventListener("change", () => {
  if (checkbox.checked) {
    button.disabled = false;
  } else {
    button.disabled = true;
  }
});

// -------------- Close button
const closeBtn = document.querySelector(".close-btn");
// const step1 = document.getElementById("step1");
// const allSteps = document.querySelectorAll(
//   "#step2, #step3, #step4, #step5, #step6, #step7, #step8, #step9, #step10, #step11, #step12, #step13, #step14, #step15, #step16, #step17"
// );

let currentStep = 1;
const maxSteps = 20; // Assume there are +30 steps on average

// Email validation function
function isValidEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

// next button and Prev Button "Most verify before click next" functionality
document.querySelectorAll(".next-button").forEach((button) => {
  button.addEventListener("click", () => {
    const current = document.getElementById(`step${currentStep}`);
    const inputs = current.querySelectorAll("input[required]");
    const errorMessage = current.querySelector(".error-message");
    let allFilled = true;
    let emailValid = true; // Email validity flag
    let phoneValid = true; // Phone number validity flag

    // Validate all required inputs except optional radio buttons
    inputs.forEach((input) => {
      if (input.type === "radio") {
        const radioGroup = current.querySelectorAll(
          `input[name='${input.name}']`
        );
        const isChecked = Array.from(radioGroup).some((radio) => radio.checked);
        if (!isChecked && input.hasAttribute("required")) {
          allFilled = false;
          radioGroup.forEach(
            (radio) => (radio.nextElementSibling.style.color = "black")
          );
        } else {
          radioGroup.forEach(
            (radio) => (radio.nextElementSibling.style.color = "")
          );
        }
      } else if (input.type === "email" && currentStep === 5) {
        // Check email validity on step 5
        if (!isValidEmail(input.value.trim())) {
          input.style.borderColor = "red"; // Highlight invalid email
          allFilled = false;
          emailValid = false;
        } else {
          input.style.borderColor = ""; // Reset border color
        }
      } else if (
        input.type === "tel" &&
        !validatePhoneNumber(input.value.trim())
      ) {
        // Check phone number validity
        input.style.borderColor = "red"; // Highlight invalid phone number
        allFilled = false;
        phoneValid = false;
      } else if (!input.value.trim()) {
        input.style.borderColor = "red"; // Highlight empty input
        allFilled = false;
      } else {
        input.style.borderColor = ""; // Reset border color
      }
    });

    // If not all fields are filled or phone number is invalid, show an error message
    if (!allFilled || !emailValid || !phoneValid) {
      if (errorMessage) {
        errorMessage.style.display = "block";
        errorMessage.textContent = !phoneValid
          ? "يرجى التأكد من صحة رقم الهاتف."
          : emailValid
          ? "يجب ملء جميع الحقول قبل الانتقال للخطوة التالية."
          : "يرجى التأكد من صحة البريد الإلكتروني أو رقم الهاتف.";
      }
    } else {
      if (errorMessage) {
        errorMessage.style.display = "none"; // Hide error message if validation passes
      }
      if (currentStep < maxSteps) {
        current.style.display = "none";
        currentStep++;
        const next = document.getElementById(`step${currentStep}`);
        next.style.display = "block";
      }
    }
  });
});

// Phone number validation
function validatePhoneNumber(phoneNumber) {
  const digitsOnly = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters
  return digitsOnly.length >= 8; // Check if there are at least 10 digits
}

// Weight limit
// document.getElementById("weight").addEventListener("input", function () {
//   var maxValue = 300;
//   if (this.value > maxValue) {
//     this.value = maxValue;
//   }
// });

// Height limit
// document.getElementById("height").addEventListener("input", function () {
//   var maxValue = 250;
//   if (this.value > maxValue) {
//     this.value = maxValue;
//   }
// });

// Age limit
// document.getElementById("age").addEventListener("input", function () {
//   var maxValue = 99;
//   if (this.value > maxValue) {
//     this.value = maxValue;
//   }
// });

// TELEPHONE max digit numbers with "+" prefix
document.getElementById("telephone").addEventListener("input", function () {
  var maxDigits = 20;
  var currentValue = this.value;

  // Remove non-digit characters
  currentValue = currentValue.replace(/\D/g, "");

  // Truncate to the maximum allowed digits
  if (currentValue.length > maxDigits) {
    currentValue = currentValue.slice(0, maxDigits);
  }

  // Update the input field with the truncated value
  this.value = currentValue;
});


// Prev button logic
document.querySelectorAll(".prev-button").forEach((button) => {
  button.addEventListener("click", () => {
    if (currentStep > 1) {
      const current = document.getElementById(`step${currentStep}`);
      current.style.display = "none";
      currentStep--;
      const prev = document.getElementById(`step${currentStep}`);
      prev.style.display = "block";
    }
  });
});

closeBtn.addEventListener("click", () => {
  window.location.reload();
});

// test Submition
const formContainer = document.querySelector(".form-container");

// ----- FOR TESTING ------
// const serviceID = "service_o93ki58";
// const templateID = "template_k77o7wd";
// const publicKey = "yqotVM92Ifdc_wl2Y";

// ----- FOR COACH ------
const serviceID = "service_tdsfml9";
const templateID = "template_4ekev0s";
const publicKey = "LNnhTvmew2vnNf1fk";

// Initialize EmailJS
emailjs.init(publicKey);

formContainer.addEventListener("submit", (e) => {

  e.preventDefault();
  // ----- STEP 2
  // Get the Date from the selected month
  const selectMonths = document.querySelector(
    `input[name="month"]:checked`
  ).value;
  // ----- STEP3
  // Get the Data from Name input
  const fullName = document.getElementById("name").value;
  // Get The Gender Data
  const gender = document.querySelector(`input[name="gender"]:checked`).value;

  // ----- STEP 4
  const weight = document.getElementById("weight").value;
  const height = document.getElementById("height").value;
  const age = document.getElementById("age").value;

  // ----- STEP 5
  // const telephone = document.getElementById("telephone").value;
  const countryCode = iti.getSelectedCountryData().dialCode; // Get country code (e.g., +212)
  const localNumber = document.getElementById("telephone").value; // The local number entered by the user
  const telephone = `+${countryCode} ${localNumber}`; // Add space between the country code and number

  const email = document.getElementById("email").value;

  // ----- STEP 6
  // Get the data from the selected Tools and methods
  const selectedMethods = [];
  document
    .querySelectorAll('input[name="methods"]:checked')
    .forEach((checkbox) => {
      selectedMethods.push(checkbox.value);
    });

  // ----- STEP 7
  // Get the data "what's you goal from this program"
  const goals = document.querySelector(`input[name="goals"]:checked`).value;

  // ----- STEP 8
  // Get the data of "Choose How many days you want to workout a week"
  const days = document.querySelector(`input[name="days"]:checked`).value;

  // ----- STEP 9
  // Get the data of Fitness level of the visitor
  const fitnessLevel = document.querySelector(
    `input[name="fitness-level"]:checked`
  ).value;

  // ----- STEP 10
  // Get the Data from "Are you student?"
  const student = document.querySelector(`input[name="student"]:checked`).value;
  // Get the Data "Jobs"
  const job = document.getElementById("job").value;
  const workoutDuration = document.getElementById("workout-duration").value;

  // ----- STEP 11
  // Get the Data from Health Issues inputs
  const healthIssueOne = document.getElementById("health-issues-1").value;
  const healthIssueTwo = document.getElementById("health-issues-2").value;
  const healthIssueThree = document.getElementById("health-issues-3").value;

  // ----- STEP 12
  // Get the that from "How many Steps you do" and "do you take any supplements"
  const stepStracking = document.getElementById("stpes-traking").value;
  const supplements = document.getElementById("supplements").value;

  // ----- STEP 13
  // Get the Data from "Eating habbits"
  const eatingHabbits = document.querySelector(
    `input[name="eating-habbits"]:checked`
  ).value;

  // ----- STEP 14
  //  Get the Data from Exercises step
  const spletWorkout = document.getElementById("splet-workout").value;
  const movePerExercise = document.getElementById("move-per-exercise").value;
  const lovedExercises = document.getElementById("loved-exercises").value;

  // ----- STEP 15
  // Get the data from Muscles and Steriods
  const musclesToFocusOn = document.getElementById("muscles-to-focus-on").value;
  const steriods = document.getElementById("steriods").value;
  const bloodTest = document.getElementById("blood-test").value;

  // ----- STEP 16
  // get the Date from "meals per day", "Cheat day" and "OMAD"
  const mealsPerDay = document.getElementById("meals-per-day").value;
  // const cheatDays = document.getElementById("cheat-days").value;
  const omad = document.getElementById("omad").value;

  // ----- STEP 17
  // Get the data from Diseases and Smooking inputs
  const diseases = document.getElementById("diseases").value;
  const smooking = document.querySelector(
    `input[name="smooking"]:checked`
  ).value;

  // ----- STEP 18
  const note = document.getElementById("note").value;

  console.log("Total Price:", totalPriceInput.value);

  // Get the full international phone number with country code
  // const fullTelephone = ` ${telephone}+ ${iti.getNumber()}`;  // Get full number with country code

  const inputData = {
    selected_plan: selectedPlanName,
    total_price: Number(totalPriceInput.value),
    selected_months: selectMonths,
    full_name: fullName,
    two_genders: gender,
    available_tools: selectedMethods,
    weight: weight,
    height: height,
    age: age,
    wts_number: telephone, // This now includes the full phone number with the country code
    email: email,
    main_goal: goals,
    training_days_per_week: days,
    fitness_level: fitnessLevel,
    is_student: student,
    job_type: job,
    workout_duration: workoutDuration,
    health_issue_one: healthIssueOne,
    health_issue_two: healthIssueTwo,
    health_issue_three: healthIssueThree,
    steps_tracking: stepStracking,
    supplements: supplements,
    eating_habits: eatingHabbits,
    current_workout_split: spletWorkout,
    moves_per_exercise: movePerExercise,
    loved_exercises: lovedExercises,
    muscles_to_focus_on: musclesToFocusOn,
    performance_enhancers: steriods,
    last_blood_test: bloodTest,
    meals_per_day: mealsPerDay,
    omad_preference: omad,
    family_diseases: diseases,
    smoking_status: smooking,
    additional_notes: note,
  };


 emailjs.send(serviceID, templateID, inputData).then(
    () => {
      // Reset the form and hide the form container
      formContainer.reset();
      popFormContainer.classList.add("hide");
      savedPriceInput.value = "";
      totalPriceInput.value = "";

      // Show a success message
      swal({
        title: "تمت عملية الإرسال بنجاح",
        icon: "success",
        text: "ستتوصل برسلة عبر الواتس من المدرب يوسف",
        className: "my-alert-theme",
        button: false,
      });

      // Reload the page after 4 seconds
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    },
    (error) => {
      console.error("Form submission error:", error);
    }
  );

  // Disable the submit button to prevent multiple submissions
  const subButton = document.querySelector(".submit-button");
  subButton.disabled = true;
  subButton.textContent = "إرسال...";
});
// Update the cpr year automaticlly
function updatCopyRight() {
  let cpr = document.getElementById("current-year");
  cpr.innerHTML = `&copy; ${new Date().getFullYear()} www.doonjooseph.com`;
}
updatCopyRight();

